<template>
  <div style="overflow: hidden">
    <div class="div-logo text-center">
      <img src="https://nixloc.com.br/wp-content/themes/nix-loc/images/logo.svg" />
    </div>
    <div class="main">
      <Register />
    </div>
    <div class="text-center">
      <a
        href="https://www.instagram.com/nixloc.sistema/?hl=pt-br"
        target="_blank"
        class="fa fa-instagram"
      ></a>
      <a
        href="https://www.facebook.com/nixloc.sistema"
        target="_blank"
        class="fa fa-facebook"
      ></a>
      <a
        href="https://www.youtube.com/channel/UC2uW-eHwBwy_EYXj9ISayWQ"
        target="_blank"
        class="fa fa-youtube"
      ></a>
      <a href="https://wa.me/551131362654" target="_blank" class="fa fa-whatsapp"></a>
    </div>
    <br />
  </div>
</template>

<script>
import Register from "../../../components/modules/adm/register/Register.vue";

export default {
  name: "RegisterView",
  components: { Register },
};
</script>

<style scoped>
.main {
  background-color: white;
  padding: 15px;
  max-width: 1200px;
  margin: auto;
  border-radius: 8px;
}

.div-logo {
  margin-top: 30px;
  padding: 18px;
}

.title {
  font-size: 20px;
}

.fa {
  padding: 20px;
  font-size: 25px;
  width: 45px;
  text-align: center;
  text-decoration: none;
  color: #c4c4c4;
}

.fa-facebook:hover {
  color: #0b86ee;
}

.fa-youtube:hover {
  color: #bb0000;
}

.fa-instagram:hover {
  color: #125688;
}

.fa-whatsapp:hover {
  color: green;
}
</style>
