<template>
  <div>
    <Messages />
    <div class="content">
      <Molded>
        <Wizard
          v-if="!success"
          :totalSteps="4"
          :formName="[
            'register.person',
            'register.contact',
            'register.address',
            'register.user',
          ]"
          :showSteps="false"
        >
          <div slot="1">
            <b-row>
              <b-col sm="1">
                <div class="step side-by-side"><span>1</span></div>
              </b-col>
              <b-col sm="10">
                <div class="title side-by-side">
                  Informe os dados da Empresa
                </div></b-col
              >
            </b-row>
            <br />
            <Person
              formName="register.person"
              v-model="register.personRequest"
              :required="true"
            />
          </div>
          <div slot="2">
            <b-row>
              <b-col sm="1">
                <div class="step side-by-side"><span>2</span></div>
              </b-col>
              <b-col sm="10">
                <div class="title side-by-side">
                  Informe os dados de contato
                </div></b-col
              >
            </b-row>
            <br />
            <Contact
              formName="register.contact"
              v-model="register.contactRequest"
              :required="true"
            />
          </div>
          <div slot="3">
            <b-row>
              <b-col sm="1">
                <div class="step side-by-side"><span>3</span></div>
              </b-col>
              <b-col sm="10">
                <div class="title side-by-side">Informe o endereço</div>
              </b-col>
            </b-row>
            <br />
            <Address
              formName="register.address"
              v-model="register.addressRequest"
              :required="true"
            />
          </div>
          <div slot="4">
            <b-row>
              <b-col sm="1">
                <div class="step side-by-side"><span>4</span></div>
              </b-col>
              <b-col sm="10">
                <div class="title side-by-side">
                  Informe o usuário administrador
                </div></b-col
              >
            </b-row>
            <br />
            <b-row>
              <b-col xs="12" sm="12" md="12" lg="4" xl="4">
                <InputText
                  title="Nome"
                  field="name"
                  :formName="formName"
                  :required="true"
                  :maxLength="50"
                  v-model="user.name"
                >
                </InputText
              ></b-col>
              <b-col xs="12" sm="12" md="12" lg="5" xl="5">
                <InputText
                  title="E-mail"
                  field="email"
                  :formName="formName"
                  :required="true"
                  :maxLength="50"
                  v-model="user.email"
                >
                </InputText
              ></b-col>
              <b-col xs="12" sm="12" md="12" lg="3" xl="3">
                <InputWhatsApp
                  title="Celular"
                  field="mobile"
                  :formName="formName"
                  :required="false"
                  :maxLength="50"
                  v-model="user.mobile"
                >
                </InputWhatsApp>
              </b-col>
            </b-row>
            <b-row>
              <b-col xs="12" sm="12" md="12" lg="6" xl="6">
                <InputText
                  title="Login"
                  field="userName"
                  :formName="formName"
                  :required="true"
                  :maxLength="50"
                  v-model="user.userName"
                >
                </InputText
              ></b-col>
              <b-col xs="12" sm="12" md="12" lg="6" xl="6">
                <InputPassword
                  title="Senha"
                  field="password"
                  :formName="formName"
                  :required="true"
                  :maxLength="50"
                  v-model="user.password"
                >
                </InputPassword></b-col
            ></b-row>
          </div>
          <div slot="finish">
            <div class="text-center">
              <Button
                _key="create"
                type="success"
                title="Cadastrar"
                size="medium"
                :disabled="!isFormValid(formName)"
                :clicked="create"
              />
            </div>
          </div>
        </Wizard>
        <div v-else>
          <b-row>
            <b-col sm="12" class="text-center">
              <div class="icon-success">
                <i class="fas fa-check"></i>
              </div>
              <div class="company">Seu cadastro foi efetuado com sucesso!</div>
              <div>
                Enviamos mais informações em seu e-mail, para entrar no sistema
                efetue o login <i class="fas fa-arrow-down"></i>
              </div>
              <br /><br />
              <Button
                _key="login"
                type="primary"
                title="Login"
                size="medium"
                :clicked="login"
              />
            </b-col>
          </b-row>
        </div>
        <br />
      </Molded>
    </div>
  </div>
</template>

<script>
import Wizard from "@nixweb/nixloc-ui/src/component/layout/Wizard.vue";
import Molded from "@nixweb/nixloc-ui/src/component/layout/Molded.vue";
import Messages from "@nixweb/nixloc-ui/src/component/shared/Messages.vue";
import Button from "@nixweb/nixloc-ui/src/component/forms/Button";
import InputText from "@nixweb/nixloc-ui/src/component/forms/InputText";
import InputWhatsApp from "@nixweb/nixloc-ui/src/component/forms/InputWhatsApp";
import InputPassword from "@nixweb/nixloc-ui/src/component/forms/InputPassword";

import Person from "@nixweb/nixloc-ui/src/component/value-objects/Person.vue";
import Contact from "@nixweb/nixloc-ui/src/component/value-objects/Contact.vue";
import Address from "@nixweb/nixloc-ui/src/component/value-objects/Address.vue";

import Register from "@/components/modules/adm/register/Register.js";

import { mapGetters, mapActions, mapMutations } from "vuex";

export default {
  name: "Register",
  components: {
    Wizard,
    Molded,
    Messages,
    Button,
    InputText,
    InputWhatsApp,
    InputPassword,
    Contact,
    Person,
    Address,
  },
  data() {
    return {
      formName: "register",
      register: new Register(),
      urlCreate: "/api/v1/adm/company/create",
      user: {
        name: "",
        email: "",
        mobile: "",
        userName: "",
        password: "",
      },
      success: false,
    };
  },
  created() {
    this.addUserLogged({});
  },
  computed: {
    ...mapGetters("validation", ["isFormValid"]),
  },
  methods: {
    ...mapActions("generic", ["postApi"]),
    ...mapMutations("generic", ["removeLoading", "removeEvent"]),
    ...mapMutations("user", ["addUserLogged"]),
    create() {
      let userRequest = {
        name: this.user.name,
        email: this.user.email,
        mobile: this.user.mobile,
        userName: this.user.userName,
        password: this.user.password,
      };
      let addressRequest = {
        zipCode: this.register.addressRequest.zipCode,
        street: this.register.addressRequest.street,
        number: this.register.addressRequest.number,
        complement: this.register.addressRequest.complement,
        province: this.register.addressRequest.province,
        city: this.register.addressRequest.city,
        state: this.register.addressRequest.state.content,
      };
      let planRequest = {
        numberOfUsers: 1,
        numberOfDocuments: 1,
        monthlyValue: 180,
      };
      let registerRequest = {
        personRequest: this.register.personRequest,
        contactRequest: this.register.contactRequest,
        addressRequest: addressRequest,
        userRequest: userRequest,
        planRequest: planRequest,
      };
      let params = { url: this.urlCreate, obj: registerRequest };
      this.postApi(params).then((response) => {
        if (response.success) this.success = true;
        this.removeLoading(["create"]);
      });
    },
    login() {
      window.location.replace("/login");
    },
  },
};
</script>
<style scoped>
.step {
  margin-top: 5px;
  height: 48px;
  width: 48px;
  font-size: 20px;
  display: grid;
  place-items: center;
  background-color: #fafafc;
  border-radius: 100%;
  border: 1px solid #dbdfe9;
  position: relative;
  color: #8ca1b7;
}

.title {
  margin-top: 9px;
  font-size: 18px;
}

.icon-success {
  font-size: 50px;
  color: #52c41a;
}

.company {
  font-size: 20px;
}
</style>
